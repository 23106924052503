/**
 * Mobile Menu - ACF/Gutenberg Block
 *
 * @author Matt Haynes
 * @package Joi Polloi
 *
 */

import anime from 'animejs/lib/anime.es';

const animateMenuItems = anime({
    targets: '.mobileNavigation__menu .menu-item',
    opacity: [0, 1],
    translateX: [-15, 0],
    duration: 750,
    delay: anime.stagger(150),
    autoplay: false,
    loop: false,
});

const mobileMenu = () => {
    const menu = document.getElementById('mobileNavigation');
    const button = document.getElementById('mobileNavigationToggle');
    if (!menu || !button) return;

    const { body } = document;

    button.addEventListener('click', () => {
        const mobileMenuActive = body.classList.contains('mobileMenuActive');

        if (mobileMenuActive) {
            body.classList.remove('mobileMenuActive');
            setTimeout(() => animateMenuItems.reset(), 400);
        } else {
            body.classList.add('mobileMenuActive');
            setTimeout(() => animateMenuItems.play(), 400);
        }
    });
};

export default mobileMenu;
