/* eslint-disable no-tabs */

const OldBrowser = () => {
    document.addEventListener('DOMContentLoaded', () => {
        if (navigator.userAgent.indexOf('MSIE') !== -1
            || navigator.appVersion.indexOf('Trident/') > -1) {
            const body = document.createElement('body');
            body.innerHTML = `
                <div id='unsupported' class='unsupported'>
                    <div class='content'>
                        <h1>Sorry</h1>
                        <p>This browser is not supported, in order to ensure a great experience, upgrade to a newer browser.</p>
                        <ul>
                            <li><a href='https://www.google.co.uk/chrome/' target='_blank' title='Chrome'><img data-no-lazy='1' width='60px' height='60px' src='/wp-content/themes/tuos_systematicreviewlution/images/chrome.svg'></a></li>
                            <li><a href='https://www.mozilla.org/en-GB/firefox/' target='_blank' title='Firefox'><img data-no-lazy='1' width='60px' height='60px' src='/wp-content/themes/tuos_systematicreviewlution/images/firefox.svg'></a></li>
                            <li><a href='https://www.microsoft.com/en-us/edge' target='_blank' title='Edge'><img data-no-lazy='1' width='60px' height='60px' src='/wp-content/themes/tuos_systematicreviewlution/images/edge.svg'></a></li>
                        </ul>
                    </div>
                </div>
            `;
            const oldBody = document.querySelector('body');
            oldBody.parentNode.replaceChild(body, oldBody);
        }
    });
};

export default OldBrowser;
