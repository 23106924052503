/**
 * Fit Text inside header
 *
 * @author Colton Lee
 * @package Joi Polloi
 *
 */

const fitHeaderText = () => {
    window.fitText = function resize(el) {
        console.log(el.outerText.length);
        if (el.outerText.length > 100) {
            // eslint-disable-next-line no-param-reassign
            el.style.fontSize = '45px';
        }
        // return
        return el;
    };
};

export default fitHeaderText;
