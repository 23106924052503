/* eslint-disable no-param-reassign */

const openAccordian = () => {
    const elements = document.querySelectorAll('.framework_item_paper');

    elements.forEach(element => {
        const panel = element.lastElementChild.lastElementChild;
        const panels = document.querySelectorAll('.panel');
        const btn = element.querySelectorAll('.expand')[0];

        btn.addEventListener('click', () => {
            panels.forEach(p => {
                if (panel !== p) {
                    p.classList.add('hidden');
                }
            });
            panel.classList.toggle('hidden');
        });
    });
};

export default openAccordian;
