// require ('promise-polyfill');
import 'promise-polyfill';

// import packageFile from '../../../package.json';
import openFramework from './framework-tabs';
import openAccordian from './framework-accordian';
import OldBrowser from './modules/OldBrowser';
import mobileMenu from './modules/mobileMenu';
import fitHeaderText from './modules/fitHeaderText';

openFramework();
openAccordian();
OldBrowser();
mobileMenu();
fitHeaderText();

const title = document.getElementsByClassName('entry-title');
window.fitText(title[0]);
