/* eslint-disable no-param-reassign */
const openFramework = () => {
    const tabLinks = Array.from(document.querySelectorAll('.tablinks'));
    const tabContent = Array.from(document.querySelectorAll('.tabcontent'));

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const tabIndex = urlParams.get('tabIndex');

    tabLinks.forEach((link, index) => {
        // activate the initial tab
        if (tabIndex && index === parseInt(tabIndex)) {
            link.className += ' active';
            document.getElementById(link.dataset.name).style.display = 'block';
        }
        // or set the index
        if (!tabIndex && index === 0) {
            urlParams.set('tabIndex', index);
            window.location.search = urlParams;
            link.className += ' active';
            document.getElementById(link.dataset.name).style.display = 'block';
        }
        link.addEventListener('click', evt => {
            tabContent.forEach(contentOther => {
                contentOther.style.display = 'none';
            });
            tabLinks.forEach(linkOther => {
                linkOther.className = 'tablinks pushable';
            });
            console.log(link.dataset.name);
            // Show the current tab, and add an "active" class to the button that opened the tab
            document.getElementById(link.dataset.name).style.display = 'block';
            evt.currentTarget.className += ' active';

            urlParams.set('tabIndex', index);
            // we dont want a clunky page reload
            // window.location.search = urlParams;
        });
    });
};

export default openFramework;
